import {icons} from '../helpers/google'


// Marker animation options: BOUNCE, DROP


export function createScaledIcon(url) {
  const isMobileDevice = window.innerWidth <= 800

  const width = isMobileDevice ? 30 : 35
  const height = width * 316 / 273


  return {
    url: url,
    anchor: new window.google.maps.Point(width / 2, height),
    scaledSize: new window.google.maps.Size(width, height),
    flat: true
  }
}



export function createMapMarker(position, type) {
  const icon = icons[type]
  const zIndex = 5
  const scaledIcon = createScaledIcon(icon)

  const marker = new window.google.maps.Marker({
    zIndex, 
    position, 
    icon: scaledIcon,
    animation: window.google.maps.Animation.DROP,
    flat: true
  })

  // Store the initial icon size on the marker
  marker.currentIconSize = {
    width: scaledIcon.scaledSize.width,
    height: scaledIcon.scaledSize.height
  }

  return marker
}